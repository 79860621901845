/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function(){
    init_product_slider(document);
});

function init_product_slider(context) {
    $('.product-slider-container', context).addClass('owl-container');
    $('.product-slider-container .product_listing', context).each(function () {
        // Add main classes
        $(this).addClass('owl-carousel');

        // Options
        $(this).owlCarousel({
            autoHeight: true,
            loop: false,
            autoplay: false,
            //autoplayTimeout: 5000,
            //autoplayHoverPause: true,
            nav: true,
            navText: [
                "<span class='icon icon-left-big'></span>",
                "<span class='icon icon-right-big'></span>",
            ],
            responsiveClass: true,
            // navContainer: '.owl-out-nav',dddddacdscvf zxxx
            slideBy: 'page',
            dots: false,
            responsive: {
                0: {
                    items: 2
                },
                750: {
                    items: 3
                },
                970: {
                    items: 4
                },
                1170: {
                    items: 5
                }
            }
        });
    });
}
